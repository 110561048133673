import { Client, Deal } from "@/app/(app)/deals/create/page";

export const RETRIEVAL_INSTRUCTION = "search_document"; // There are only four instructions in nomic and for retrieval embedding, they recommend "search_document"
export const QUESTION_RETRIEVAL_INSTRUCTION = "search_document";
export const QUERY_INSTRUCTION = "search_query";
export const COMPARE_INSTRUCTION = "Represent the sentence for comparison: ";
export const GET_DOCUMENTS_FN = "get_documents";
export const SEARCH_DOCUMENT_CHUNKS_FN = "search_knowledge_base";
export const GET_DEAL_FN = "get_deal";
export const SEARCH_DEALS_FN = "select_deal";
export const DEAL_INFO_FN = "search_deal_info";
export const GET_TEMPLATE_FN = "get_template";
export const REVISE_FN = "revise";
export const DRAFT_FN = "draft";
export const DRAFT_EMAIL_FN = "draft_email";
export const DRAFT_RFP_FN = "draft_rfp";
export const DRAFT_SQ_FN = "draft_sq";
export const CUSTOMIZE_TEMPLATE_FN = "customize_template";
export const GENERATE_DOCUMENT_FN = "generate_document";
export const HUBSPOT_FN = "query_hubspot";
export const OPENAI_MODEL = "gpt-4o";
export const OPENAI_MINI_MODEL = "gpt-4o-mini";
export const CLAUDE_MODEL = "claude-3-5-sonnet-20240620";

// A generic message where embedding is compared to check if Paige doesn't know the answer.
export const UNSURE_MESSAGES = [
  "I couldn't find any specific information",
  "No answers could be found in the knowledge base.",
];

// Clerk Org Roles
export const CLERK_ADMIN = "admin";
export const CLERK_MEMBER = "basic_member";

// Mime Types
export const PDF_MIME_TYPE = "application/pdf";
export const DOCX_MIME_TYPE =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
export const XLSX_MIME_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const PPTX_MIME_TYPE =
  "application/vnd.openxmlformats-officedocument.presentationml.presentation";
export const OFFICE_MIME_TYPES = [
  XLSX_MIME_TYPE,
  PPTX_MIME_TYPE,
  DOCX_MIME_TYPE,
];
export const PLAIN_TEXT_MIME_TYPE = "text/plain";
export const MARKDOWN_MIME_TYPE = "text/markdown";
export const JPEG_MIME_TYPE = "image/jpeg";
export const PNG_MIME_TYPE = "image/png";
export const GOOGLE_DOCS_MIME_TYPE = "application/vnd.google-apps.document";
export const GOOGLE_SHEETS_MIME_TYPE =
  "application/vnd.google-apps.spreadsheet";
export const GOOGLE_SLIDES_MIME_TYPE =
  "application/vnd.google-apps.presentation";
export const GOOGLE_FOLDER_MIME_TYPE = "application/vnd.google-apps.folder";
export const MP4_MIME_TYPE = "video/mp4";
export const AVI_MIME_TYPE = "video/x-msvideo";
export const MOV_MIME_TYPE = "video/quicktime";
export const EXTERNAL_URL_MIME_TYPE = "external_link";
export const YOUTUBE_VIDEO_MIME_TYPE = "Youtube";
export const LOOM_MIME_TYPE = "Loom";

export const SALESFORCE_DTYPES = [
  "string",
  "boolean",
  "int",
  "double",
  "date",
  "datetime",
  "base64",
  "ID",
  "reference",
  "currency",
  "textarea",
  "percent",
  "phone",
  "url",
  "email",
  "combobox",
  "picklist",
  "multipicklist",
  "anyType",
  "location",
];
export const HUBSPOT_DTYPES = [
  "bool",
  "enumeration",
  "date",
  "dateTime",
  "string",
  "number",
  "object_coordinates",
  "json",
];

export const HUBSPOT_DATE_DTYPES = ["date", "dateTime"];
export const SALESFORCE_DATE_DTYPES = ["date", "datetime"];

export const SALESFORCE_DTYPE_TO_TS_TYPE: Record<string, string> = {
  string: "string",
  boolean: "boolean",
  int: "number",
  double: "number",
  date: "string",
  datetime: "string",
  // base64: "string", // We dont want to filter by base64
  // ID: "string", // we dont want to filter by ID
  // reference: "string", // Not sure what might be the best type for this
  currency: "number",
  textarea: "string",
  percent: "number",
  phone: "string",
  url: "string",
  email: "string",
  combobox: "string",
  picklist: "string",
  multipicklist: "string",
  // anyType: "string", // Not sure what might be the best type for this
  location: "string",
};
export const HUBSPOT_DTYPE_TO_TS_TYPE: Record<string, string> = {
  bool: "boolean",
  enumeration: "string",
  date: "string",
  dateTime: "string",
  string: "string",
  number: "number",
  // object_coordinates: "string", // Not sure what might be the best type for this
  // json: "string", // Not sure what might be the best type for this
};

export const MIME_TYPE_TO_EXTENSION: Record<string, string> = {
  [PDF_MIME_TYPE]: "pdf",
  [DOCX_MIME_TYPE]: "docx",
  [XLSX_MIME_TYPE]: "xlsx",
  [PPTX_MIME_TYPE]: "pptx",
  [PLAIN_TEXT_MIME_TYPE]: "txt",
  [MARKDOWN_MIME_TYPE]: "md",
  [JPEG_MIME_TYPE]: "jpeg",
  [PNG_MIME_TYPE]: "png",
};

export const EXTENSION_TO_ONLYOFFICE_DOCUMENT_TYPE: Record<string, string> = {
  pdf: "pdf",
  docx: "word",
  xlsx: "cell",
  pptx: "slide",
  txt: "word",
};

export const EXTENSION_TO_MIME_TYPE: Record<string, string> = {
  pdf: PDF_MIME_TYPE,
  docx: DOCX_MIME_TYPE,
  xlsx: XLSX_MIME_TYPE,
  pptx: PPTX_MIME_TYPE,
  txt: PLAIN_TEXT_MIME_TYPE,
  md: MARKDOWN_MIME_TYPE,
  jpeg: JPEG_MIME_TYPE,
  png: PNG_MIME_TYPE,
};

export const VIDEO_MIME_TYPES = [
  MP4_MIME_TYPE,
  AVI_MIME_TYPE,
  MOV_MIME_TYPE,
  YOUTUBE_VIDEO_MIME_TYPE,
];

export const IMAGE_MIME_TYPES = [JPEG_MIME_TYPE, PNG_MIME_TYPE];
// Deal Status
export const DEAL_CREATED_STATUS = "Deal Opened";
export const QUALIFIED_STATUS = "Qualified";
export const PRE_SALES_STATUS = "Pre-Sales";
export const WON_STATUS = "Won";
export const ONBOARDING_STATUS = "Onboarding";
export const POST_SALES_STATUS = "Support";
export const LOST_STATUS = "Lost";

export const DEAL_CREATED_ID = "dealopened";
export const QUALIFIED_ID = "qualified";
export const PRE_SALES_ID = "presales";
export const WON_ID = "won";
export const ONBOARDING_ID = "onboarding";
export const POST_SALES_ID = "postsales";
export const LOST_ID = "lost";

export const DEAL_STATUS_TO_ID: Record<string, string> = {
  [DEAL_CREATED_STATUS]: DEAL_CREATED_ID,
  [QUALIFIED_STATUS]: QUALIFIED_ID,
  [PRE_SALES_STATUS]: PRE_SALES_ID,
  [WON_STATUS]: WON_ID,
  [ONBOARDING_STATUS]: ONBOARDING_ID,
  [POST_SALES_STATUS]: POST_SALES_ID,
  [LOST_STATUS]: LOST_ID,
};

export const STATUS_TO_NUMBER: Record<string, number> = {
  [DEAL_CREATED_STATUS]: 1,
  [QUALIFIED_STATUS]: 2,
  [PRE_SALES_STATUS]: 3,
  [WON_STATUS]: 4,
  [ONBOARDING_STATUS]: 5,
  [POST_SALES_STATUS]: 6,
  [LOST_STATUS]: 7,
};

export const NUMBER_TO_STATUS: Record<number, string> = {
  0: "No status",
  1: DEAL_CREATED_STATUS,
  2: QUALIFIED_STATUS,
  3: PRE_SALES_STATUS,
  4: WON_STATUS,
  5: ONBOARDING_STATUS,
  6: POST_SALES_STATUS,
  7: LOST_STATUS,
};

// Doc status
export const DRAFT_STATUS = "Draft";
export const DRAFT_CREATED_STATUS = "Draft Created";
export const UNDER_REVIEW_STATUS = "Under Review";
export const APPROVED_STATUS = "Approved";
export const SUBMITTED_STATUS = "Submitted";

// Task status
export const IN_PROGRESS_STATUS = "In Progress";
export const DONE_STATUS = "Done";

export const STATUS_OPTIONS = [
  DEAL_CREATED_STATUS,
  QUALIFIED_STATUS,
  PRE_SALES_STATUS,
  WON_STATUS,
  LOST_STATUS,
  ONBOARDING_STATUS,
  POST_SALES_STATUS,
];

export const DOC_STATUS_OPTIONS = [
  DRAFT_CREATED_STATUS,
  UNDER_REVIEW_STATUS,
  APPROVED_STATUS,
  SUBMITTED_STATUS,
];

export const ORGANIZATION_LABEL = "Organization";
export const PRODUCTS_LABEL = "Products";
export const PEOPLE_LABEL = "People";
export const CONTENT_LABEL = "Content";
export const SECURITY_LABEL = "Security";
export const CLOUD_LABEL = "Cloud";
export const NETWORK_LABEL = "Network";
export const ACCESS_LABEL = "Access";
export const ASSETS_LABEL = "Assets";
export const INCIDENTS_LABEL = "Incidents";
export const DATA_LABEL = "Data";
export const DEVELOPMENT_LABEL = "Development";
export const CRYPTOGRAPHY_LABEL = "Cryptography";
export const VENDORS_LABEL = "Vendors";
export const DISASTER_LABEL = "Disaster";

// Priority Constants

// Nango Integrations
export const NANGO_GOOGLE_DRIVE_INTEGRATION = "google";
export const NANGO_CONFLUENCE_INTEGRATION = "confluence";
export const NANGO_NOTION_INTEGRATION = "notion";
export const NANGO_SALESFORCE_INTEGRATION = "salesforce";
export const NANGO_HUBSPOT_INTEGRATION = "hubspot";
export const NANGO_GMAIL_INTEGRATION = "google-mail";
export const NANGO_ONEDRIVE_INTEGRATION = "one-drive";
export const NANGO_SHAREPOINT_INTEGRATION = "sharepoint-online";

// Trigger Task Display Keys
export const INSERT_DOCUMENT_TASK = "insert-document";
export const INVALIDATE_CACHE_TASK = "invalidate-cache";
export const DELETE_PERMISSION_TASK = "delete-permission";
export const SPREADSHEET_DRAFT_TASK = "spreadsheet-draft";
export const DOCUMENT_DRAFT_TASK = "document-draft";
export const DRAFT_SECTION_TASK = "draft-section";
export const DRAFT_QUESTION_TASK = "draft-question";
export const WRITE_DRAFT_TASK = "write-draft";
export const REFORMAT_DRAFT_TASK = "reformat-draft";
export const EXTRACT_REQUIREMENTS_TASK = "extract-requirements";
export const DRAFT_OUTLINE_TASK = "draft-outline";
export const DRAFT_SHEET_OUTLINE_TASK = "draft-sheet-outline";
export const ENRICH_CONTACT_TASK = "enrich-contact";
export const UPDATE_CLIENT_TASK = "update-client";
export const INSERT_DOCUMENT_PERMISSIONS_TASK = "insert-document-permissions";
export const DELETE_DOCUMENT_CHUNKS_TASK = "delete-document-chunks";
export const DELETE_FROM_ZILLIZ_TASK = "delete-from-zilliz";
export const DELETE_DOCUMENT_TASK = "delete-document";
export const UNDERSTAND_DOCUMENT_TASK = "understand-document";
export const REGENERATE_CONTEXT_TASK = "regenerate-context";
export const UPDATE_DEAL_TASK = "update-deal";
export const PARSE_JSON_TASK = "parse-json";
export const DOWNLOAD_OBJECT_TASK = "download-object";
export const DOWNLOAD_DRIVE_FILE_TASK = "download-drive-file";
export const DOWNLOAD_SLACK_FILE_TASK = "download-slack-file";
export const DOWNLOAD_CONFLUENCE_FILE_TASK = "download-confluence-file";
export const DOWNLOAD_NOTION_FILE_TASK = "download-notion-file";
export const PARSE_BUFFER_TASK = "parse-buffer";
export const CONVERT_DOCUMENT_TASK = "convert-document";
export const UPDATE_DOCUMENT_TASK = "update-document";
export const SEARCH_DOCUMENTS_TASK = "search-documents";
export const SEARCH_DEALS_TASK = "search-deals";
export const PLANNING_RESPONSE_TASK = "planning-response";
export const GATHERING_BACKGROUND_TASK = "gathering-background";
export const UPDATE_VANTA_KEY_TASK = "update-vanta-key";
export const UPLOAD_FILE_TASK = "upload-file";
export const EMBED_DOCUMENT_TASK = "embed-document";
export const INSERT_DOCUMENT_CHUNKS_TASK = "insert-document-chunks";
export const INSERT_VESPA_TASK = "insert-vespa";
export const SELECT_DEAL_TASK = "select-deal";
export const SUMMARIZE_DOCUMENT_TASK = "summarize-document";
export const UPDATE_DOCUMENT_SUMMARY_TASK = "update-document-summary";
export const SELECT_VANTA_KEY_TASK = "select-vanta-key";
export const QUERY_VANTA_TASK = "query-vanta";
export const SYNC_HUBSPOT_ASSOCIATIONS_TASK = "sync-hubspot-associations";
export const GET_HUBSPOT_ASSOCIATIONS_TASK = "get-hubspot-associations";
export const GET_SALESFORCE_OPPORTUNITIES_TASK = "get-salesforce-opportunities";
export const SELECT_DOCUMENTS_TASK = "select-documents";
export const SEND_DRAFT_NOTIFICATION_TASK = "send-draft-notification";
export const SELECT_NOTIFICATION_SETTINGS_TASK = "select-notification-settings";
export const SEND_DRAFT_EMAIL_TASK = "send-draft-email";
export const SEND_DRAFT_SLACK_TASK = "send-draft-slack";
export const SELECT_SLACK_INSTALL_TASK = "select-slack-install";
export const RESPOND_SLACK_TASK = "respond-slack";
export const GENERATE_DOC_ID_TASK = "generate-doc-id";
export const INSERT_QUESTION_TASK = "insert-question";
export const DELETE_QUESTION_TASK = "delete-question";
export const INSERT_CLIENT_TASK = "insert-client";
export const DELETE_CLIENT_TASK = "delete-client";
export const INSERT_DEAL_TASK = "insert-deal";
export const DELETE_DEAL_TASK = "delete-deal";
export const INSERT_VERIFIED_ANSWER_TASK = "insert-verified-answer";
export const DELETE_VERIFIED_ANSWER_TASK = "delete-verified-answer";
export const ENRICH_ORG_TASK = "enrich-org";
export const REWRITE_QUESTION_TASK = "rewrite-question";
export const REWRITE_WITH_DEAL_CONTEXT = "rewrite-with-deal-context";
export const CUSTOMIZE_TEMPLATE_TASK = "customize-template";
export const DRAFT_EXECUTIVE_SUMMARY_TASK = "draft-executive-summary";
export const EXTRACT_VARIABLES_TASK = "extract-variables";
export const FILL_VARIABLE_TASK = "fill-variable";

// Deal Activity Types
export const DEAL_CREATED = "DEAL_CREATED";
export const STATUS_UPDATED = "STATUS_UPDATED";
export const DUE_DATE_UPDATED = "DUE_DATE_UPDATED";
export const COLLABORATOR_ADDED = "COLLABORATORS_ADDED";
export const COLLABORATOR_REMOVED = "COLLABORATORS_REMOVED";
export const COLLABORATOR_UPDATED = "COLLABORATORS_UPDATED";
export const DEAL_DOCUMENT_UPLOADED = "DEAL_DOCUMENT_UPLOADED";
export const DOCUMENT_DELETED = "DOCUMENT_DELETED";
export const SALESFORCE_SYNCED = "SALESFORCE_SYNCED";
export const HUBSPOT_SYNCED = "HUBSPOT_SYNCED";
export const RFP_RESPONSE_DRAFTED = "RFP_RESPONSE_DRAFTED";
export const SQ_RESPONSE_DRAFTED = "SQ_RESPONSE_DRAFTED";
export const ADDED_CONTACT = "ADDED_CONTACT";

// Trigger Tasks that we will show in the UI
export const SHOWN_TRIGGER_TASKS = [
  INSERT_DOCUMENT_PERMISSIONS_TASK,
  EMBED_DOCUMENT_TASK,
  SUMMARIZE_DOCUMENT_TASK,
  DOWNLOAD_DRIVE_FILE_TASK,
  DOWNLOAD_NOTION_FILE_TASK,
  UNDERSTAND_DOCUMENT_TASK,
  REGENERATE_CONTEXT_TASK,
  UPDATE_DEAL_TASK,
  DOCUMENT_DRAFT_TASK,
  SPREADSHEET_DRAFT_TASK,
  DRAFT_SECTION_TASK,
  QUERY_VANTA_TASK,
  EXTRACT_REQUIREMENTS_TASK,
  DRAFT_OUTLINE_TASK,
  ENRICH_CONTACT_TASK,
  UPDATE_CLIENT_TASK,
  DRAFT_SHEET_OUTLINE_TASK,
  REWRITE_QUESTION_TASK,
  SYNC_HUBSPOT_ASSOCIATIONS_TASK,
  GET_SALESFORCE_OPPORTUNITIES_TASK,
  CUSTOMIZE_TEMPLATE_TASK,
  DRAFT_EXECUTIVE_SUMMARY_TASK,
  SEARCH_DEALS_TASK,
  PLANNING_RESPONSE_TASK,
  GATHERING_BACKGROUND_TASK,
];

export const TRIGGER_TASK_DISPLAY_NAMES: Record<string, string> = {
  [INSERT_DOCUMENT_PERMISSIONS_TASK]: "Inserting document permissions",
  [EMBED_DOCUMENT_TASK]: "Embedding document",
  [SUMMARIZE_DOCUMENT_TASK]: "Summarizing content",
  [DOWNLOAD_DRIVE_FILE_TASK]: "Downloading from Drive",
  [DOWNLOAD_CONFLUENCE_FILE_TASK]: "Downloading from Confluence",
  [DOWNLOAD_NOTION_FILE_TASK]: "Downloading from Notion",
  [UNDERSTAND_DOCUMENT_TASK]: "Understanding document",
  [REGENERATE_CONTEXT_TASK]: "Regenerating context",
  [UPDATE_DEAL_TASK]: "Updating deal",
  [DOCUMENT_DRAFT_TASK]: "Drafting document",
  [DRAFT_SECTION_TASK]: "Drafting section",
  [DRAFT_QUESTION_TASK]: "Drafting question",
  [QUERY_VANTA_TASK]: "Downloading from Vanta",
  [EXTRACT_REQUIREMENTS_TASK]: "Extracting requirements",
  [DRAFT_OUTLINE_TASK]: "Drafting outline",
  [ENRICH_CONTACT_TASK]: "Enriching contact",
  [UPDATE_CLIENT_TASK]: "Updating client",
  [DRAFT_SHEET_OUTLINE_TASK]: "Drafting sheet outline",
  [REWRITE_QUESTION_TASK]: "Rewriting question with context",
  [SYNC_HUBSPOT_ASSOCIATIONS_TASK]: "Syncing Hubspot deal",
  [GET_SALESFORCE_OPPORTUNITIES_TASK]: "Syncing Salesforce opportunity",
  [CUSTOMIZE_TEMPLATE_TASK]: "Customizing template",
  [DRAFT_EXECUTIVE_SUMMARY_TASK]: "Drafting executive summary",
};

export const WORKFLOW_OPTIONS: Deal[] = [
  {
    name: "Request For Proposal",
    acronym: "RFP",
    description: "Draft a response to an RFP (Request For Proposal).",
  },
  {
    name: "Security Questionnaire",
    acronym: "SQ",
    description: "Draft a response to a security questionnaire.",
  },
  {
    name: "Customize a Template",
    acronym: "Customize",
    description: "Customize a template from your template library.",
  },
  {
    name: "Generate a Document",
    acronym: "Generate",
    description: "Have Paige generate a document for you.",
  },
  {
    name: "Blank Document",
    acronym: "BD",
    description: "Start from scratch.",
  },
  {
    name: "Draft an Email",
    acronym: "Email",
    description:
      "Reply to customer questions or share a document with a client.",
  },
];

export const TASK_WORKFLOW_OPTIONS: string[] = [
  "Draft an email",
  "Customize a template",
  "Draft a document",
  "Start a chat",
];

export const EDITABLE_DOC_TYPES: string[] = [
  "proposal",
  "questionnaire",
  "custom_document",
  "customized_template",
];

export const CUSTOM_DOC_TYPES: string[] = [
  "Proposal",
  "Onboarding",
  "Custom Document",
  "Customized Template",
];

export const VIEW_ONLY_DOC_TYPES: string[] = ["rfp", "sq", "info", "template"];

export const READABLE_DOC_TYPES: { [key: string]: string } = {
  proposal: "RFP Response",
  questionnaire: "SQ Response",
  custom_document: "Custom Document",
  custom_proposal: "Proposal",
  rfp: "RFP",
  sq: "Security Questionnaire",
  info: "Deal Context",
  template: "Template",
};

export const timePeriod: string[] = [
  "Today",
  "Last 7 days",
  "Last month",
  "Last 3 months",
  "Last 6 months",
  "All time",
];

export const timePeriodToPosthogFilter: Record<string, string> = {
  Today: "dStart",
  "Last 7 days": "-7d",
  "Last month": "-30d",
  "Last 3 months": "-90d",
  "Last 6 months": "-180d",
  "All time": "all",
};

export const PAGEVIEW_EVENT = "$pageview";
export const DOCUMENT_SIGNED_EVENT = "document_signed";
export const DOCUMENT_SENT_FOR_SIGNATURE_EVENT = "document_sent_for_signature";
export const DOCUMENT_CREATED_FOR_SHARING_EVENT =
  "document_created_for_sharing";
export const DOCUMENT_COPIED_TO_DEAL_EVENT = "document_copied_to_deal";
export const DOCUMENT_CREATED_EVENT = "document_created";
export const CHAT_STARTED_EVENT = "guest_chat_started";
export const TEMPLATE_USED_EVENT = "template_used";
export const DOCUMENT_PAGE_VIEWED_EVENT = "document_page_viewed";
export const CHAT_LOG_EVENT = "chat_log";
export const DRAFT_FEEDBACK_EVENT = "draft_feedback";
export const DEAL_RECOMMENDATION_EVENT = "deal_recommendation_used";
export const MEETING_LOG_EVENT = "meeting_log";
export const EMAIL_LOG_EVENT = "email_log";
export const DEAL_CREATED_EVENT = "deal_created";
export const DEAL_STATUS_CHANGED_EVENT = "deal_status_changed";
export const DEAL_CLOSED_EVENT = "deal_closed";
export const RELATED_DOCUMENT_CLICKED_EVENT = "related_document_clicked";
export const DOCUMENT_DOWNLOAD_EVENT = "document_downloaded";
export const QUESTION_ASKED_EVENT = "question_asked";

export const EVENT_TO_DESCRIPTION: Record<string, string> = {
  [DOCUMENT_CREATED_EVENT]: "created this document",
  [PAGEVIEW_EVENT]: "viewed this document",
  [DOCUMENT_SIGNED_EVENT]: "signed this document",
  [DOCUMENT_SENT_FOR_SIGNATURE_EVENT]: "sent this document for signature",
  [DOCUMENT_CREATED_FOR_SHARING_EVENT]: "created this document for sharing",
  [CHAT_STARTED_EVENT]: "started a chat",
};

export const DEFAULT_SCHEMA = {
  "template-schema": {
    type: "object",
    title: "Template data",
  },
  "template-bool": {
    type: "boolean",
    format: "checkbox",
  },
  "template-text": {
    type: "string",
    format: "textarea",
  },
  "template-object": {
    type: "object",
  },
  "template-loop": {
    type: "array",
    items: {
      type: "object",
    },
  },
  "template-row-loop": {
    $ref: "#/definitions/template-loop",
    format: "table",
  },
  "template-image": {
    type: "object",
    properties: {
      image_url: {
        $ref: "#/definitions/template-text",
        propertyOrder: 1,
        format: "url/file-download",
      },
      width: {
        type: "string",
        title: "width",
        propertyOrder: 2,
      },
      height: {
        type: "string",
        title: "height",
        propertyOrder: 3,
      },
    },
  },
  "template-markdown": {
    type: "object",
    properties: {
      markdown: {
        $ref: "#/definitions/template-text",
      },
    },
  },
  "template-html": {
    type: "object",
    properties: {
      html: {
        $ref: "#/definitions/template-text",
      },
    },
  },
  "template-content": {
    anyOf: [
      {
        title: "text",
        $ref: "#/definitions/template-text",
      },
      {
        title: "image",
        $ref: "#/definitions/template-image",
      },
      {
        title: "markdown",
        $ref: "#/definitions/template-markdown",
      },
      {
        title: "html",
        $ref: "#/definitions/template-html",
      },
    ],
  },
};

export const DOC_VIEWER_SCHEMA = {
  "template-schema": {
    type: "object",
    title: "Template data",
  },
  "template-bool": {
    type: "boolean",
    format: "checkbox",
  },
  "template-text": {
    type: "string",
    format: "textarea",
  },
  "template-object": {
    type: "object",
  },
  "template-loop": {
    type: "array",
    items: {
      type: "object",
    },
  },
  "template-row-loop": {
    $ref: "#/definitions/template-loop",
    format: "table",
  },
  "template-image": {
    type: "object",
    properties: {
      image_url: {
        $ref: "#/definitions/template-text",
        propertyOrder: 1,
        format: "url/file-download",
      },
      width: {
        type: "string",
        title: "width",
        propertyOrder: 2,
      },
      height: {
        type: "string",
        title: "height",
        propertyOrder: 3,
      },
    },
  },
  "template-markdown": {
    type: "object",
    properties: {
      markdown: {
        $ref: "#/definitions/template-text",
      },
    },
  },
  "template-html": {
    type: "object",
    properties: {
      html: {
        $ref: "#/definitions/template-text",
      },
    },
  },
  "template-content": {
    type: "object",
    properties: {
      description: {
        $ref: "#/definitions/template-text",
      },
    },
  },
};

export const QUOTE_TEMPLATE = "Quote";
export const PROPOSAL_TEMPLATE = "Proposal";
export const SOW_TEMPLATE = "Statement of Work";
export const MSA_TEMPLATE = "Master Services Agreement";
export const NDA_TEMPLATE = "Non-Disclosure Agreement";
export const PITCH_DECK_TEMPLATE = "Pitch Deck";
export const SLA_TEMPLATE = "Service-Level Agreement";
export const ONBOARDING_DOCUMENT = "Onboarding";
export const SUPPORT_ARTICLE = "Support Article";
export const ONE_PAGER = "One Pager";
export const CASE_STUDY = "Case Study";
export const WHITEPAPER = "Whitepaper";
export const BLOG_POST = "Blog Post";
export const EMAIL = "Email";
export const MEETING = "Meeting";
export const EBOOK = "Ebook";
export const WEBINAR = "Webinar";
export const VIDEO = "Video";
export const DEMO = "Demo";
export const TESTIMONIAL = "Testimonial";
export const OTHER_TEMPLATE = "Other";
export const BATTLE_CARD = "Battle Card";
export const SALES_PRESENTATION = "Sales Presentation";
export const SALES_PLAYBOOK = "Sales Playbook";
export const BROCHURE = "Brochure";
export const DATASHEET = "Datasheet";

export const LABEL_OPTIONS = [
  EMAIL,
  MEETING,
  PROPOSAL_TEMPLATE,
  PITCH_DECK_TEMPLATE,
  ONE_PAGER,
  CASE_STUDY,
  WHITEPAPER,
  BLOG_POST,
  QUOTE_TEMPLATE,
  SOW_TEMPLATE,
  MSA_TEMPLATE,
  NDA_TEMPLATE,
  SLA_TEMPLATE,
  ONBOARDING_DOCUMENT,
  SUPPORT_ARTICLE,
  EBOOK,
  WEBINAR,
  VIDEO,
  DEMO,
  OTHER_TEMPLATE,
  CONTENT_LABEL,
  DATA_LABEL,
  DEVELOPMENT_LABEL,
  ORGANIZATION_LABEL,
  PEOPLE_LABEL,
  PRODUCTS_LABEL,
  SECURITY_LABEL,
  SALES_PLAYBOOK,
  BROCHURE,
  DATASHEET,
];

export const TEMPLATE_TYPES = [
  PROPOSAL_TEMPLATE,
  PITCH_DECK_TEMPLATE,
  ONE_PAGER,
  CASE_STUDY,
  WHITEPAPER,
  BLOG_POST,
  QUOTE_TEMPLATE,
  SOW_TEMPLATE,
  MSA_TEMPLATE,
  NDA_TEMPLATE,
  SLA_TEMPLATE,
  ONBOARDING_DOCUMENT,
  SUPPORT_ARTICLE,
  EBOOK,
  WEBINAR,
  VIDEO,
  DEMO,
  OTHER_TEMPLATE,
];

export const BILLING_FREQUENCY = [
  "One Time",
  "Monthly",
  "Quarterly",
  "Semi-Annually",
  "Yearly",
  "Every 2 Years",
  "Every 3 Years",
  "Every 4 Years",
  "Every 5 Years",
];

export const CONTENT_LENGHTS = [
  "One word",
  "A few words",
  "A sentence",
  "A paragraph",
  "Multiple paragraphs",
];

export const REPORT_QUESTIONS = [
  {
    section: "General",
    questions: [
      {
        question:
          "What are the primary pain points the customer is experiencing?",
      },
      {
        question:
          "What are the main objectives the customer is hoping to achieve with our solution?",
      },
    ],
  },
  {
    section: "Solution Details",
    questions: [
      {
        question: "What solution are we proposing to the customer?",
      },
      {
        question:
          "What key features of our solution address the customer's pain points?",
      },
      {
        question:
          "What is the value proposition of our solution for this customer?",
      },
      {
        question: "What pricing model and terms have been proposed?",
      },
      {
        question:
          "What is the estimated return on investment for the customer?",
      },
    ],
  },
  {
    section: "Challenges / Risks",
    questions: [
      {
        question:
          "What challenges or objections have been raised by the customer?",
      },
      {
        question: "What technical risks or product gaps exist?",
      },
      {
        question: "Who are the main competitors in this deal?",
      },
    ],
  },
  {
    section: "Sales Process",
    questions: [
      {
        question: "What are the next steps in the sales process?",
      },
      {
        question: "What internal resources or teams are involved in this deal?",
      },
    ],
  },
  {
    section: "Implementation Plan",
    questions: [
      {
        question: "What is the proposed timeline for implementation?",
      },
      {
        question: "How ready is the customer for implementation?",
      },
    ],
  },
];




export const DEFAULT_HUBSPOT_DEAL_FIELDS = [
  {id: "dealname", name: "Deal Name", segment: false, disabled: true},
  {id: "dealstage", name: "Deal Stage", segment: false, disabled: true},
  {id: "amount", name: "Amount", segment: false, disabled: true},
  {id: "closedate", name: "Close Date", segment: false, disabled: true},
  {id: "hs_lastmodifieddate", name: "Last Modified Date", segment: false, disabled: true},
  {id: "hs_closed_amount", name: "Closed Amount", segment: false, disabled: true},
  {id: "hs_closed_amount_currency_code", name: "Closed Amount Currency", segment: false, disabled: true},
  {id: "hs_forecast_amount", name: "Forecast Amount", segment: false, disabled: true},
  {id: "hs_forecast_amount_currency_code", name: "Forecast Amount Currency", segment: false, disabled: true},
  {id: "hs_pipeline", name: "Pipeline", segment: false, disabled: true},
  {id: "hs_pipeline_stage", name: "Pipeline Stage", segment: false, disabled: true},
  {id: "dealtype", name: "Deal Type", segment: false, disabled: true},
  {id: "description", name: "Description", segment: false, disabled: true},
];

export const DEFAULT_HUBSPOT_ASSOCIATIONS = [
  {id: "companies", name: "Companies", segment: false, disabled: true},
  {id: "contacts", name: "Contacts", segment: false, disabled: true},
  {id: "line_items", name: "Line Items", segment: false, disabled: true},
  {id: "meetings", name: "Meetings", segment: false, disabled: true},
  {id: "notes", name: "Notes", segment: false, disabled: true},
  {id: "emails", name: "Emails", segment: false, disabled: true},
  {id: "tickets", name: "Tickets", segment: false, disabled: true},
];

export const DEFAULT_HUBSPOT_ACOUNT_FIELDS = [
  {id: "name", name: "Name", segment: false, disabled: true},
  {id: "industry", name: "Industry", segment: false, disabled: true},
  {id: "city", name: "City", segment: false, disabled: true},
  {id: "state", name: "State", segment: false, disabled: true},
  {id: "country", name: "Country", segment: false, disabled: true},
  {id: "domain", name: "Domain", segment: false, disabled: true},
  {id: "description", name: "Description", segment: false, disabled: true},
  {id: "about_us", name: "About Us", segment: false, disabled: true},
  {id: "founded_year", name: "Founded Year", segment: false, disabled: true},
  {id: "annualrevenue", name: "Annual Revenue", segment: false, disabled: true},
  {id: "website", name: "Website", segment: false, disabled: true},
  {id: "linkedin_company_page", name: "LinkedIn Company Page", segment: false, disabled: true},
  {id: "numberofemployees", name: "Number of Employees", segment: false, disabled: true},
  {id: "hs_lead_status", name: "Lead Status", segment: false, disabled: true},
  {id: "hs_createdate", name: "Create Date", segment: false, disabled: true},
  {id: "hs_lastmodifieddate", name: "Last Modified Date", segment: false, disabled: true},
  {id: "total_money_raised", name: "Total Money Raised", segment: false, disabled: true},
];

export const DEFAULT_SALESFORCE_OPPORTUNITY_FIELDS = [
  {id: "Id", name: "Id", segment: false, disabled: true},
  {id: "Name", name: "Name", segment: false, disabled: true},
  {id: "Description", name: "Description", segment: false, disabled: true},
  {id: "Amount", name: "Amount", segment: false, disabled: true},
  {id: "StageName", name: "Stage Name", segment: false, disabled: true},
  {id: "CloseDate", name: "Close Date", segment: false, disabled: true},
];

export const DEFAULT_SALESFORCE_ASSOCIATION_FIELDS = [
  {id: "Account", name: "Account", segment: false, disabled: true},
  {id: "OpportunityContactRole", name: "Contact", segment: false, disabled: true},
  {id: "OpportunityLineItem", name: "Line Item", segment: false, disabled: true},
  {id: "EmailMessage", name: "Email", segment: false, disabled: true},
  {id: "Note", name: "Note", segment: false, disabled: true},
  {id: "ContentDocumentLink", name: "Document", segment: false, disabled: true},
  {id: "FeedItem", name: "Feed Item", segment: false, disabled: true},
  {id: "Event", name: "Event", segment: false, disabled: true},
]

export const DEFAULT_SALESFORCE_ACCOUNT_FIELDS = [
  {id: "Id", name: "Id", segment: false, disabled: true},
  {id: "Name", name: "Name", segment: false, disabled: true},
  {id: "Industry", name: "Industry", segment: false, disabled: true},
  {id: "AnnualRevenue", name: "Annual Revenue", segment: false, disabled: true},
  {id: "BillingAddress", name: "Billing Address", segment: false, disabled: true},
  {id: "BillingCity", name: "Billing City", segment: false, disabled: true},
  {id: "BillingCountry", name: "Billing Country", segment: false, disabled: true},
  {id: "BillingState", name: "Billing State", segment: false, disabled: true},
  {id: "Description", name: "Description", segment: false, disabled: true},
  {id: "NumberOfEmployees", name: "Number of Employees", segment: false, disabled: true},
  {id: "Phone", name: "Phone", segment: false, disabled: true},
  {id: "Website", name: "Website", segment: false, disabled: true}, 
]
